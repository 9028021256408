<template>
  <div class="page-dashboard">
    <resize-observer @notify="__resizeHanlder" />
    <el-row class="" :gutter="30">
      <el-col>
        <div class="flex justify-space-between">
          <h1>Dashboard</h1>
          <div
            v-intro="
              'You can customise the period over which your dashboard displays. More options can be enabled in the Display Settings.'
            "
            v-intro-step="2"
          >
            <div class="radio-switcher">
              <el-radio-group v-model="period">
                <el-radio-button
                  v-for="item in displayPeriods"
                  :key="item.value"
                  :label="item.value"
                  >{{ item.label }}</el-radio-button
                >
              </el-radio-group>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row class="mt-30" :gutter="30">
      <el-col :xs="24" :sm="12" :md="14" :lg="16" :xl="18">
        <div
          v-intro="
            'The buckets card shows all of your buckets and how much each outgoing bucket is costing you.'
          "
          v-intro-step="1"
        >
          <div class="card-base card-shadow--medium mb-30">
            <div class="p-20 flex justify-space-between">
              <div>
                <h2 class="mv-0 animated fadeInDown">Bucket Overview</h2>
                <h4 class="mt-5 mb-0 o-050 animated slideInUp">
                  By {{ period | capitalize }}
                </h4>
              </div>
            </div>
            <div class="table-box">
              <el-table
                :data="tableData"
                :default-sort="{ prop: 'amount', order: 'descending' }"
                style="width: 100%"
              >
                <el-table-column
                  prop="bucket"
                  label="Bucket"
                  sortable
                  width="180"
                >
                </el-table-column>
                <el-table-column
                  prop="amount"
                  label="Amounts Due"
                  sortable
                  width="180"
                >
                  <template slot-scope="scope">{{
                    scope.row.amount | currency
                  }}</template>
                </el-table-column>
                <el-table-column label="% of Income" sortable>
                  <template slot-scope="scope"
                    ><el-progress :percentage="scope.row.progress"></el-progress
                  ></template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="10" :lg="8" :xl="6">
        <div
          class="card-base card-shadow--medium mb-10 widget small-widget"
          v-loading="!asyncComponent"
        >
          <div class="widget-header ph-20 pt-20">
            <div class="flex justify-center align-center">
              <div class="widget-icon-box mr-20 animated fadeInRight">
                <i
                  class="widget-icon mdi mdi-cash-multiple success-text fs-30"
                ></i>
                <div
                  v-intro-hint="
                    'Shows how much cash you have left at the end of the period'
                  "
                >
                  <div class="badge-box">
                    <span class="badge"
                      ><strong class="success-text">{{
                        (totalIncomes - totalOutgoings) | currency
                      }}</strong></span
                    >
                  </div>
                </div>
              </div>
              <div
                class="widget-info box grow text-truncate animated fadeInLeft"
              >
                <div class="o-050 widget-title text-truncate pt-5 pb-10">
                  Incoming
                </div>
                <h2 class="m-0 text-truncate">{{ totalIncomes | currency }}</h2>
              </div>
            </div>
          </div>

          <component
            :is="asyncComponent"
            :type="'bar'"
            :options="{ width: '100%', height: 80, fill: ['#56f19a'] }"
            :data="incomes.toString()"
          />
        </div>

        <div
          class="card-base card-shadow--medium mb-30 widget small-widget"
          v-loading="!asyncComponent"
        >
          <div class="widget-header ph-20 pt-20">
            <div class="flex justify-center align-center">
              <div class="widget-icon-box mr-20 animated fadeInRight">
                <i class="widget-icon mdi mdi-receipt danger-text fs-30"></i>
                <div
                  v-intro-hint="
                    'Shows the your outcomes as a percentage of total income'
                  "
                >
                  <div class="badge-box">
                    <span class="badge"
                      ><i class="mdi mdi-trending-down info-text mr-10"></i
                      ><strong class="danger-text"
                        >{{ outgoingPercentageOfIncome }}%</strong
                      ></span
                    >
                  </div>
                </div>
              </div>
              <div
                class="widget-info box grow text-truncate animated fadeInLeft"
              >
                <div class="o-050 widget-title text-truncate pt-5 pb-10">
                  Outgoing
                </div>
                <h2 class="m-0 text-truncate">
                  {{ totalOutgoings | currency }}
                </h2>
              </div>
            </div>
          </div>

          <component
            :is="asyncComponent"
            :type="'bar'"
            :options="{ width: '100%', height: 80, fill: ['#ec205f'] }"
            :data="outgoings.toString()"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { periodMap, periods } from "@/store/helpers";
import _ from "lodash";

export default {
  name: "Dashboard",
  data() {
    return {
      asyncComponent: "peity",
      resized: false,
      period: "week",
    };
  },
  computed: {
    smallWidget() {
      return (
        this.dashboardWidth >= 970 &&
        this.dashboardWidth <= 1412 &&
        this.windowWidth >= 1200
      );
    },
    totalOutgoings() {
      return this.outgoing_data.reduce((total, item) => {
        var a_sum = 0;
        if (item.hasOwnProperty("amount")) {
          a_sum = periodMap[item["frequency"]][this.period](item["amount"]);
        }

        return a_sum + total;
      }, 0);
    },
    totalIncomes() {
      return this.income_data.reduce((total, item) => {
        var a_sum = 0;
        if (item.hasOwnProperty("amount")) {
          a_sum = periodMap[item["frequency"]][this.period](item["amount"]);
        }

        return a_sum + total;
      }, 0);
    },
    buckets() {
      var totalIncome = this.totalIncomes;
      var buckets = {};
      this.outgoing_data.forEach((item) => {
        var item_amount = periodMap[item["frequency"]][this.period](
          item["amount"]
        );
        if (!(item.category in buckets)) {
          buckets[item.category] = {
            amount: item_amount,
            progress: Math.round(
              (parseFloat(item_amount) / parseFloat(totalIncome)) * 100
            ),
          };
          return;
        }

        var final_amount = (buckets[item.category]["amount"] += item_amount);
        buckets[item.category]["progress"] = Math.round(
          (parseFloat(final_amount) / parseFloat(totalIncome)) * 100
        );
      });

      return buckets;
    },
    tableData() {
      let tableData = [];

      for (let bucketName in this.buckets) {
        let rowData = this.buckets[bucketName];
        rowData["bucket"] = bucketName;
        tableData.push(rowData);
      }

      return tableData;
    },
    outgoings() {
      return this.outgoing_data.map((outgoing) => outgoing.amount);
    },
    outgoingPercentageOfIncome() {
      return Math.round((this.totalOutgoings / this.totalIncomes) * 100);
    },
    incomes() {
      return this.income_data.map((income) => income.amount);
    },
    ...mapState({
      income_data: (state) =>
        state.income.tableData.filter(
          (row) => row.saved == true && row.editing == false
        ),
      outgoing_data: (state) =>
        state.outgoing.tableData.filter(
          (row) => row.saved == true && row.editing == false
        ),
      hintsEnabled: (state) => state.settings.showHints,
      tutorialEnabled: (state) => state.settings.showTutorial,
      displayPeriods: (state) =>
        periods.filter((period) =>
          state.settings.displayPeriods.includes(period.value)
        ),
    }),
  },
  methods: {
    __resizeHanlder: _.throttle(function () {
      if (this.resized) {
        this.asyncComponent = null;
        this.removePeity();
        setTimeout(() => {
          this.asyncComponent = "peity";
          this.showHints();
          this.showTutorial();
        }, 1000);
      }
      this.resized = true;
    }, 700),
    removePeity() {
      const peityEl = document.querySelectorAll(".widget .peity"); //.forEach((el)=>{el.remove()})
      //ie fix
      for (let i = 0; i < peityEl.length; i++) {
        peityEl[i].parentNode.removeChild(peityEl[i]);
      }
    },
    showHints() {
      if (this.hintsEnabled) {
        // this.$intro().showHints()
        return;
      }
      this.$intro().hideHints();
    },
    showTutorial() {
      if (this.tutorialEnabled) {
        // this.$intro().start()
        return;
      }
      this.$intro().exit(true);
    },
  },
  mounted() {
    setTimeout(() => {
      this.showHints();
      this.showTutorial();
    }, 1000);
  },
  beforeDestroy() {
    this.$intro().hideHints();
  },
  filters: {
    capitalize: function (str) {
      if (!str) return "";
      str = str.toString();
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.widget {
  height: 200px;
  position: relative;

  .widget-header {
    .widget-icon-box {
      background: rgba(0, 0, 0, 0.02);
      border: 1px solid rgba(0, 0, 0, 0.02);
      border-radius: 4px;
      text-align: center;
      width: 60px;
      padding: 5px;
    }

    .widget-title {
      font-weight: bold;
    }
  }

  .badge-box {
    .badge {
      //background: rgba(0, 0, 0, .02);
      display: inline-block;
      //padding: 2px 5px;
      //border: 1px solid rgba(0, 0, 0, .02);
      border-radius: 4px;
      font-size: 80%;
    }
  }
}

.timeline {
  max-width: 1200px;
  margin: 6px;
}
.timeline,
.timeline-title {
  color: $text-color;
  line-height: 1.4;
  cursor: default;
  font-family: inherit;
}

.radio-switcher {
  margin-block-start: 1.83em;
}

/*@media (min-width: 1200px) and (max-width: 1850px) {
	.widget {
		.widget-header {
			.widget-icon-box {
				display: none;
			}
		}
	}
}*/
@media (min-width: 768px) and (max-width: 1040px) {
  .radio-switcher {
    display: none;
  }

  .widget {
    .widget-header {
      .widget-icon-box {
        display: none;
      }
    }
  }
}
@media (max-width: 420px) {
  .radio-switcher {
    display: none;
  }
}
</style>

<style lang="scss">
.table-box {
  overflow: auto;
}

.page-dashboard {
  padding-right: 30px;
  .widget {
    .peity {
      position: absolute;
      bottom: -1px;
      left: 0;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  table.styled {
    .peity {
      margin-right: 10px;
    }
  }

  .vb-content {
    padding: 0 20px;
    box-sizing: border-box !important;
    margin-top: -5px;
    margin-left: -20px;
    margin-right: -20px;
    height: calc(100% + 15px) !important;
    width: calc(100% + 40px) !important;
  }
}

@media (max-width: 768px) {
  .page-dashboard {
    .vb-content {
      padding: 0 5px !important;
      margin: -5px;
      width: calc(100% + 10px) !important;
    }
  }
}
</style>



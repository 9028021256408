<template>
	<div class="page-table scrollable">
		<div class="page-header">
			<h1>Display Settings
			</h1>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/' }">
				<i class="mdi mdi-home-outline"></i>
				</el-breadcrumb-item>
				<el-breadcrumb-item>Account</el-breadcrumb-item>
				<el-breadcrumb-item>Display Settings</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<el-row class="mt-30" :gutter="30">
			<el-col>
				<div class="table-box card-base card-shadow--medium pt-20 ph-40 pb-20">
					<h3>Frequency Options</h3>
					<div class="page-profile-edit">
						<el-row v-for="item in periods" :key="item.value">
							<el-col class="mb-20">
											<el-checkbox 
											:label="item.label"
											:checked="frequency_options.includes(item.value)"
											@change="updatePeriods({itemName: item.value, shouldShow: $event})"></el-checkbox>
							</el-col>
						</el-row>
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {periods} from "@/store/helpers.js";
export default {
	name: 'DisplaySettings',
	data() {
		return {
            periods,
        }
	},
	methods: {
		resizeLabelPosition() {
			if(window.innerWidth <= 768) {
				this.labelPosition = 'top'	
			}
		},
        ...mapActions("settings",["updatePeriods"])
	},
    computed: {
        ...mapState("settings", {
            frequency_options: 'displayPeriods'
        })
    },
	mounted() {
		this.resizeLabelPosition();
		window.addEventListener('resize', this.resizeLabelPosition);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resizeLabelPosition);
	}
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables';

.page-profile-edit {
	padding-right: 30px;
	.label-switch-box {
		display: block;
		clear: both;
		width: 100%;
		text-align: right;
		margin-bottom: 20px;
	}
	.col-p {
		padding: 0 10px;
		box-sizing: border-box;
	}
	.select-wide {
		width: 100%;
	}
}
</style>

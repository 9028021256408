<template>
	<vue-scroll class="login-page align-vertical">
		<div class="form-wrapper align-vertical-middle">
			<el-alert
				title="Error!"
				type="error"
				:description="error"
				:closable="true"
				v-show="error"
				show-icon>
			</el-alert>
			<div class="form-box card-base card-shadow--extraLarge" v-loading="loading" v-on:keyup.enter.stop="login()">
				<img class="image-logo" src="/main.svg" alt="logo"/>
				
				<float-label class="styled">
					<input type="email" v-model="form.email" placeholder="Email">
				</float-label>
				<float-label class="styled">
					<input type="password" v-model="form.password" placeholder="Password">
				</float-label>
				
				<!-- <div class="flex">
					<div class="box grow"><el-checkbox>Remember Me </el-checkbox></div>
					<div class="box grow text-right"><router-link to="/dashboard">Forgot Password?</router-link></div>
				</div> -->

				<div class="flex text-center center pt-30 pb-10">			
					<el-button plain size="small" @click="login()" class="login-btn">
						LOGIN
					</el-button>
				</div>

				<div class="text-center signin-box pt-20">
					Don't have an account? <a @click="goToRegister()">Sign Up</a>
				</div>
			</div>
		</div>
	</vue-scroll>
</template>

<script>
import firebase from "firebase/app";

export default {
	name: 'Login',
	data() {
		return {
			form: {
				email: '',
				password: '',
			},
			error: '',
			loading: false
		}
	},
	methods: {
		login() {
			this.error = ''
			this.loading = true
			firebase.auth()
			.signInWithEmailAndPassword(this.form.email, this.form.password)
			.then(data => {
				this.$store.commit('setLogin', {email: data.user.email, displayName: data.user.displayName, id: data.user.uid})
				this.$router.push('dashboard')
			})
			.catch(err => {
				this.loading = false
				this.error = err.message;
			});
		},
		goToRegister() {
			this.$router.push('register')
		}
	}
}
</script>

<style lang="scss">
@import '../../../assets/scss/_variables';

.login-page {
	background: $text-color;
	margin-left: -45px;
	margin-right: -45px;

	.form-wrapper {
		width: 100%;
	}

	.el-alert {
		max-width: 340px;
		margin: 20px auto;
	}
	
	.form-box {
		width: 100%;
		max-width: 340px;
		padding: 30px;
		box-sizing: border-box;
		margin: 20px auto;

		a {
			font-size: 14px;
			color: $text-color-accent;
			text-decoration: none;
			font-weight: 500;
		}

		.image-logo {
			width: 80px;
			margin: 0px auto;
			margin-bottom: 30px;
			display: block;
		}

		.login-btn ,
		.social-btn {
			width: 160px;

			&.google {
				margin-bottom: 10px;
				background-color: #d73d32;
				color: white;

				&:hover {
					border-color: #d73d32;
				}
			}
			&.facebook {
				background-color: #3f5c9a;
				color: white;

				&:hover {
					border-color: #3f5c9a;
				}
			}
		}

		.signin-box {
			font-size: 14px;
		}
	}
}

@media (max-width: 768px) {
	.layout-container .container .view.login-page {
		margin-left: -5px;
		margin-right: -5px;
		max-width: calc(100% + 10px);
	}
}
</style>

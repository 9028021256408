import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDkmRM0cxBDkn4Snkab0IxoqWLaVM8mbcY",
    authDomain: "do-better-with-cash-452ff.firebaseapp.com",
    projectId: "do-better-with-cash-452ff",
    storageBucket: "do-better-with-cash-452ff.appspot.com",
    messagingSenderId: "93956775934",
    appId: "1:93956775934:web:fb907518eef251b950eaaf",
    measurementId: "G-CX964TYWZW"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
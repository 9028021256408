import Vue from "vue";

export default {
  namespaced: true,
  mutations: {
    setTutorial(state, value) {
      state.showTutorial = value;
    },
    setHints(state, value) {
      state.showHints = value;
    },
    addDisplayPeriod(state, value) {
      state.displayPeriods.push(value);
    },
    removeDisplayPeriod(state, value) {
      state.displayPeriods = state.displayPeriods.filter(
        period => period != value
      );
    }
  },
  actions: {
    toggleTutorial({ commit, state }, force) {
      if (force != null) {
        commit("setTutorial", force);
        return;
      }
      commit("setTutorial", !state.showTutorial);
    },
    toggleHints({ commit, state }, force) {
      if (force != null) {
        commit("setHints", force);
        return;
      }
      commit("setHints", !state.showHints);
    },
    updatePeriods({ commit, state }, { itemName, shouldShow }) {
      if (state.displayPeriods.includes(itemName)) {
        if (!shouldShow) {
          commit("removeDisplayPeriod", itemName);
        }
      } else {
        if (shouldShow) {
          commit("addDisplayPeriod", itemName);
        }
      }
    }
  },
  state: {
    showHints: true,
    showTutorial: true,
    displayPeriods: ["week", "bi-week", "month", "annual"]
  }
};

import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import income from "@/store/income";
import outgoing from "@/store/outgoing";
import settings from "@/store/settings";
import firebase from "firebase/app";
import { mergeWith } from "lodash";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    income,
    outgoing,
    settings
  },
  state: {
    layout: {
      navPos: "left", //top, bottom, left, right, false
      toolbar: "top", //top, bottom, false
      footer: true, //above, below, false
      boxed: false, //true, false
      roundedCorners: false, //true, false
      viewAnimation: "fade" // fade-left, fade-right, fade-top, fade-top-in-out, fade-bottom, fade-bottom-in-out, fade, false
    },
    splashScreen: true,
    user: null
  },
  mutations: {
    setLayout(state, payload) {
      if (payload && payload.navPos !== undefined)
        state.layout.navPos = payload.navPos;

      if (payload && payload.toolbar !== undefined)
        state.layout.toolbar = payload.toolbar;

      if (payload && payload.footer !== undefined)
        state.layout.footer = payload.footer;

      if (payload && payload.boxed !== undefined)
        state.layout.boxed = payload.boxed;

      if (payload && payload.roundedCorners !== undefined)
        state.layout.roundedCorners = payload.roundedCorners;

      if (payload && payload.viewAnimation !== undefined)
        state.layout.viewAnimation = payload.viewAnimation;
    },
    setLogin(state, payload) {
      state.user = { ...payload };
    },
    setLogout(state, payload) {
      state.layout.navPos = null;
      state.layout.toolbar = null;
      state.user = null;
      firebase
        .auth()
        .signOut()
        .then(() => {
          payload();
        });
    },
    setSplashScreen(state, payload) {
      state.splashScreen = payload;
    },
    replaceState(state, payload) {
      state = mergeWith(state, payload, (objValue, srcValue) => {
        if (Array.isArray(objValue)) {
          return srcValue;
        }
      });
    }
  },
  actions: {
    resetState({ commit, state }, newState = {}) {
      return new Promise((resolve, reject) => {
        if (typeof newState !== "object") {
          return reject("newState not an object");
        }
        commit("replaceState", newState);
        return resolve(true);
      });
    }
  },
  getters: {
    layout(state, getters) {
      return state.layout;
    },
    navPos(state, getters) {
      return state.layout.navPos;
    },
    toolbar(state, getters) {
      return state.layout.toolbar;
    },
    footer(state, getters) {
      return state.layout.footer;
    },
    boxed(state, getters) {
      return state.layout.boxed;
    },
    roundedCorners(state, getters) {
      return state.layout.roundedCorners;
    },
    viewAnimation(state, getters) {
      return state.layout.viewAnimation;
    },
    isLogged(state, getters) {
      return state.user;
    },
    splashScreen(state, getters) {
      return state.splashScreen;
    }
  },
  plugins: [
    createPersistedState({
      paths: ["layout", "income", "outgoing", "settings"]
    }),
    createPersistedState({
      storage: window.sessionStorage,
      paths: ["user"]
    })
  ],
  strict: debug
});

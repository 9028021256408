<template>
	<div class="scrollable">
		<div class="page-header">
			<h1>Import/Export Data
			</h1>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/' }">
				<i class="mdi mdi-home-outline"></i>
				</el-breadcrumb-item>
				<el-breadcrumb-item>Account</el-breadcrumb-item>
				<el-breadcrumb-item>Import/Export Data</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<el-alert
			title="How To"
			type="success"
			description="To save your data copy everything from the &quot;Current Data&quot; field and save it somewhere safe. To recover
			your data simply paste your previous data copy into the &quot;New Data&quot; field and click &quot;Import Data&quot;."
			:closable="false"
			class="mb-20"
			show-icon>
		</el-alert>
		<el-alert
			title="Warning!"
			type="warning"
			description="This is an advanced user feature and you could lose all your data if you do this incorrectly."
			:closable="false"
			show-icon>
		</el-alert>
		<div class="page-profile-edit">
			<el-row class="mt-30" :gutter="30">
				<el-col>
					<div class="table-box card-base card-shadow--medium pt-20 ph-40 pb-20">
						<el-form ref="form" :model="form" label-width="120px" :label-position="labelPosition">
							<el-col class="col-p">
								<el-form-item label="Current Data">
									<el-input type="textarea" :value="currentData" autosize disabled></el-input>
								</el-form-item>
							</el-col>
							<el-col class="col-p">
								<el-form-item>
									<el-button type="primary" @click="getDataFromPrompt()" v-loading.fullscreen.lock="loading">Import Data From Text</el-button>
								</el-form-item>
							</el-col>
							<el-col class="col-p">
								<el-form-item label="Cloud Data">
									<el-button type="primary" @click="getDataFromCloud()" v-loading.fullscreen.lock="loading">Import from Cloud</el-button>
									<el-button type="primary" @click="saveDataToCloud()" v-loading.fullscreen.lock="loading">Save to Cloud</el-button>
								</el-form-item>
							</el-col>
						</el-form>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import base64js from 'base64-js'
import firebase from 'firebase/app'
import "firebase/storage"

function convertToBase64 (obj) {
	return base64js.fromByteArray(Array.prototype.map.call(
		JSON.stringify(obj), letter => {
			return letter.charCodeAt(0)
		}
	))
}

function convertFromBase64 (str) {
	return JSON.parse(String.fromCharCode(...base64js.toByteArray(str)))
}

export default {
	name: 'DataSettings',
	data() {
		return {
			labelPosition: 'right',
			loading: false,
			form: {
				newData: ''
			},
		}
	},
	methods: {
		saveDataToCloud() {
			// TODO: Perform billing check
			this.loading = true
			const storageRef = firebase.storage().ref();
			const saveFile = storageRef.child(`user/${this.user.id}/savestate.b64`)
			// TODO: Show loading state
			saveFile.putString(this.currentData).then(() => {
				// TODO: Show success message
				this.loading = false
				this.$notify({
					title: 'Success',
					message: 'Cloud data save completed.',
					type: 'success'
				});
			})
			.catch((error) => {
				this.$notify({
					title: 'Error',
					message: 'Cloud data save failed. ' + error.error.message,
					type: 'error'
				});
				this.loading = false
			})
		},
		retrieveCloudSave(url) {
			fetch(url).then(res => res.text()).then(contents => {
				if ((contents.match(/\|/g) || []).length != 2) {
					return
				}
				var [settings, outgoing, income]  = contents.split('|')
				settings = convertFromBase64(settings)
				outgoing = convertFromBase64(outgoing)
				income = convertFromBase64(income)
				this.resetState({
					settings,
					outgoing,
					income
				}).then(() => {
					this.form.newData = ''
					this.loading = false
					this.$notify({
						title: 'Success',
						message: 'Cloud data import completed.',
						type: 'success'
					});
				})
			})
			
		},
		getDataFromCloud() {
			// TODO: Perform billing check
			this.loading = true
			const storageRef = firebase.storage().ref();
			storageRef.child(`user/${this.user.id}/savestate.b64`).getDownloadURL().then(this.retrieveCloudSave, err => {
				this.$notify({
					title: 'Error',
					message: 'Cloud data import failed. ' + err.error.message,
					type: 'error'
				});
			})
		},
		getDataFromPrompt() {
			this.$prompt('Please enter your save data', 'Import Save Data', {
				confirmButtonText: 'Import',
				cancelButtonText: 'Cancel',
				inputPattern: /\|/g,
				inputErrorMessage: 'Invalid Save Data'
			}).then(result => {
				this.loading = true
				if ((result.value.match(/\|/g) || []).length != 2) {
					this.loading = false
					this.$message({
						message: 'Save data invalid.',
						type: 'error'
					});
					return
				}

				var [settings, outgoing, income]  = result.value.split('|')
				settings = convertFromBase64(settings)
				outgoing = convertFromBase64(outgoing)
				income = convertFromBase64(income)
				this.resetState({
					settings,
					outgoing,
					income
				}).then(() => {
					this.loading = false
					this.$notify({
						title: 'Success',
						message: 'Data import completed.',
						type: 'success'
					});
				})
			}).catch((err) => {
				console.log(err)
				this.loading = false
			});
		},
		onSubmit() {
			if ((this.form.newData.match(/\|/g) || []).length != 2) {
				this.$notify({
					title: 'Error',
					message: 'No data or import data invalid.',
					type: 'error'
				});
				return
			}
			
		},
		resizeLabelPosition() {
			if(window.innerWidth <= 768) {
				this.labelPosition = 'top'	
			}
		},
		clearInput() {
			this.form.newData = ''
		},
		...mapActions(["resetState"])
	},
	mounted() {
		this.resizeLabelPosition();
		window.addEventListener('resize', this.resizeLabelPosition);
	},
	computed: {
		...mapState(['settings', 'income', 'outgoing', 'user']),
		currentData: function (){
			var settings = convertToBase64(this.settings)
			var outgoings = convertToBase64(this.outgoing)
			var income = convertToBase64(this.income)
			return `${settings}|${outgoings}|${income}`
		}
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resizeLabelPosition);
	}
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables';

.page-profile-edit {
	padding-right: 30px;
	.label-switch-box {
		display: block;
		clear: both;
		width: 100%;
		text-align: right;
		margin-bottom: 20px;
	}
	.col-p {
		padding: 0 10px;
		box-sizing: border-box;
	}
	.select-wide {
		width: 100%;
	}
}
</style>

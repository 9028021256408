import Vue from "vue";
import Router from "vue-router";

//pages
import Login from "../views/pages/authentication/Login.vue";
import Register from "../views/pages/authentication/Register.vue";
import ForgotPassword from "../views/pages/authentication/ForgotPassword.vue";
import Profile from "../views/pages/Profile.vue";
import NotFound from "../views/pages/NotFound.vue";

// General
import Dashboard from "../views/general/Dashboard.vue";
import Income from "../views/general/Income.vue";
import Outgoings from "../views/general/Outgoings.vue";
import Simulator from "../views/general/Simulator.vue";

// Account
import DisplaySettings from "../views/settings/Display.vue";
import DataSettings from "../views/settings/Data.vue";

// Sales
import Home from "../views/sales/Home.vue";

import layouts from "../layout";
import store from "../store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: {
        layout: layouts.contenOnly,
        title: "Home | DoBetterWith.Cash"
      }
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard,
      meta: {
        auth: true,
        layout: layouts.navLeft,
        searchable: true,
        tags: ["general"],
        title: "Dashboard | DoBetterWith.Cash"
      }
    },
    {
      path: "/simulator",
      name: "simulator",
      component: Simulator,
      meta: {
        auth: true,
        layout: layouts.navLeft,
        searchable: true,
        tags: ["general"],
        title: "Simulator | DoBetterWith.Cash"
      }
    },
    {
      path: "/incomes",
      name: "incomes",
      component: Income,
      meta: {
        auth: true,
        layout: layouts.navLeft,
        searchable: true,
        tags: ["general"],
        title: "Incomes | DoBetterWith.Cash"
      }
    },
    {
      path: "/outgoings",
      name: "outgoings",
      component: Outgoings,
      meta: {
        auth: true,
        layout: layouts.navLeft,
        searchable: true,
        tags: ["general"],
        title: "Outgoings | DoBetterWith.Cash"
      }
    },

    {
      path: "/settings/display",
      name: "displays_settings",
      component: DisplaySettings,
      meta: {
        auth: true,
        layout: layouts.navLeft,
        searchable: true,
        tags: ["account"],
        title: "Display Settings | DoBetterWith.Cash"
      }
    },

    {
      path: "/settings/data",
      name: "data_settings",
      component: DataSettings,
      meta: {
        auth: true,
        layout: layouts.navLeft,
        searchable: true,
        tags: ["account"],
        title: "Data Settings | DoBetterWith.Cash"
      }
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      meta: {
        auth: true,
        layout: layouts.navLeft,
        searchable: true,
        tags: ["pages"],
        title: "Profile| DoBetterWith.Cash"
      }
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        layout: layouts.contenOnly,
        title: "Login | DoBetterWith.Cash"
      }
    },
    {
      path: "/register",
      name: "register",
      component: Register,
      meta: {
        layout: layouts.contenOnly,
        title: "Register | DoBetterWith.Cash"
      }
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: ForgotPassword,
      meta: {
        layout: layouts.contenOnly,
        title: "Forgot Password | DoBetterWith.Cash"
      }
    },
    {
      path: "/logout",
      beforeEnter(to, from, next) {
        auth.logout(() => {
          router.push({"path": "/login"})
        });
        return next()
      }
    },
    {
      path: "*",
      name: "not-found",
      component: NotFound,
      meta: {
        layout: layouts.contenOnly,
        title: "404 Not Found | DoBetterWith.Cash"
      }
    }
  ]
});

const l = {
  contenOnly() {
    store.commit("setLayout", layouts.contenOnly);
  },
  navLeft() {
    store.commit("setLayout", layouts.navLeft);
  },
  navRight() {
    store.commit("setLayout", layouts.navRight);
  },
  navTop() {
    store.commit("setLayout", layouts.navTop);
  },
  navBottom() {
    store.commit("setLayout", layouts.navBottom);
  },
  set(layout) {
    store.commit("setLayout", layout);
  }
};

//insert here login logic
const auth = {
  loggedIn() {
    return store.getters.isLogged;
  },
  logout(fn) {
    store.commit("setLogout", fn);
  }
};

router.beforeEach((to, from, next) => {
  let authrequired = false;
  if (to && to.meta && to.meta.auth) authrequired = true;

  if (authrequired) {
    if (auth.loggedIn()) {
      if (to.name === "login") {
        return next({ path: "/dashboard" });
      } else {
        next();
      }
    } else {
      if (to.name !== "login") {
        return next({ path: "/login" });
      }
      next();
    }
  } else {
    if (auth.loggedIn() && to.name === "login") {
      return next({ path: "/dashboard" });
    } else {
      next();
    }
  }

  if (to && to.meta && to.meta.layout) {
    l.set(to.meta.layout);
  }
});

router.afterEach(() => {
  setTimeout(() => {
    store.commit("setSplashScreen", false);
  }, 500);
});

export default router;

<template>
  <div class="page-table scrollable">
    <div class="page-header">
      <h1>Outgoings
        <el-button size="mini" icon="el-icon-plus" @click="addOutgoing()" round></el-button>
      </h1>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">
          <i class="mdi mdi-home-outline"></i>
        </el-breadcrumb-item>
        <el-breadcrumb-item>General</el-breadcrumb-item>
        <el-breadcrumb-item>Outgoings</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!--<div class="table-box padding-top">
        <el-select
              v-model="frequencyDisplay"
              placeholder="Select Frequency"
              size="small"
            >
            <el-option
            v-for="item in frequency_options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            ></el-option>
        </el-select>
        <span> | </span>
        <el-tag
              type="primary"
            >{{getOutgoingsByPeriod(frequencyDisplay) | currency}}</el-tag>
    </div>-->
    <div class="table-box card-base card-shadow--medium">
      <el-table :data="tableData" style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="name" label="Name" width="180">
          <template slot-scope="scope">
            <div @click="handleEdit(scope.$index)" v-show="!scope.row.editing">{{scope.row.name}}</div>
            <el-input v-model.lazy="scope.row.name" v-show="scope.row.editing"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="Date" sortable width="240">
          <template slot-scope="scope">
            <div
              @click="handleEdit(scope.$index)"
              v-show="!scope.row.editing"
            >{{scope.row.date | moment("MMMM Do, YYYY")}}</div>
            <el-date-picker
              v-model="scope.row.date"
              type="date"
              v-show="scope.row.editing"
              placeholder="Date money leaves"
              format="MMM d yyyy"
            ></el-date-picker>
          </template>
        </el-table-column>
        <el-table-column width="180" prop="frequency" label="Frequency">
          <template slot-scope="scope">
            <div
              @click="handleEdit(scope.$index)"
              v-show="!scope.row.editing"
            >{{frequencyValue(scope.row.frequency)}}</div>
            <el-select
              v-model="scope.row.frequency"
              placeholder="Select Frequency"
              v-show="scope.row.editing"
            >
              <el-option
                v-for="item in periods"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          prop="category"
          label="Bucket"
          width="180"
          :filters="categoryUnique"
          :filter-method="filterTag"
          filter-placement="bottom-end"
        >
          <template slot-scope="scope">
            <el-tag
              type="primary"
              v-show="!scope.row.editing"
              close-transition
            >{{scope.row.category}}</el-tag>
            <el-tooltip class="item" effect="dark" content="Create a new category by typing a category name and clicking in the list below" placement="top">
            <el-select
              v-model="scope.row.category"
              v-show="scope.row.editing"
              filterable
              allow-create
              default-first-option
              placeholder="Choose a category"
            >
              <el-option v-for="item in categories" :key="item" :label="item" :value="item">
                <span style="float: left">{{ item }}</span>
                <span v-show="!categories.includes(item)" style="float: right; color: #8492a6; font-size: 12px">Click To Add</span>
              </el-option>
            </el-select>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="Amount">
          <template slot-scope="scope">
            <div @click="handleEdit(scope.$index)" v-show="!scope.row.editing">{{scope.row.amount | currency}}</div>
            <!-- <el-input size="small" v-model.lazy="scope.row.amount" v-show="scope.row.editing" clearable=true ></el-input> -->
            <Money :fixed="2" v-model.lazy="scope.row.amount" v-show="scope.row.editing"></Money>
          </template>
        </el-table-column>
        <el-table-column label="" width="100">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              v-if="!scope.row.editing"
              @click="handleEdit(scope.$index)"
              round
            ></el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              v-if="!scope.row.editing"
              @click="deleteOutgoing(scope.$index)"
              round
            ></el-button>
            <el-button
              size="mini"
              icon="el-icon-close"
              v-if="scope.row.editing"
              @click="handleEdit(scope.$index)"
              round
            ></el-button>
            <el-button
              type="primary"
              size="mini"
              icon="mdi mdi-content-save"
              v-if="scope.row.editing"
              @click="handleSave(scope.$index)"
              round
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import Money from "@/components/Money";
import {periods} from "@/store/helpers.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { createHelpers } from "vuex-map-fields";
const { mapMultiRowFields } = createHelpers({
  getterType: "outgoing/getField",
  mutationType: "outgoing/updateField"
});

export default {
  name: "Outgoing",
  data: function() {
      return {
        frequencyDisplay: 'week',
        periods: periods
    }
  },
  computed: {
    categoryUnique: function() {
      let tableDate = [
        ...new Set(
          this.tableData.map(item => ({
            text: item.category,
            value: item.category
          }))
        )
      ];
      return tableDate;
    },
    ...mapState("settings", ['displayPeriods']),
    ...mapState("outgoing", {
      inputPlaceholder: "inputPlaceholder",
      input: "input",
      categories: "categories"
    }),
    ...mapMultiRowFields(["tableData"]),
    ...mapGetters("outgoing",["getOutgoingsByPeriod"]),
  },
  methods: {
    ...mapMutations("outgoing", {
      addOutgoing: "addOutgoing",
      deleteOutgoing: "deleteOutgoing",
      replaceOutgoing: "replaceOutgoing"
    }),
    ...mapActions("outgoing", {
      handleEdit: "handleEdit",
      handleSave: "handleSave"
    }),
    formatter(row, column) {
      return row.address;
    },
    filterTag(value, row) {
      return row.category === value;
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    frequencyValue(key) {
      let row = periods.find(row => {
        return key == row.value;
      });

      if (row) {
        return row.label;
      }

      return "";
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(arr) {
      this.multipleSelection = arr;
    }
  },
  components: {
    Money
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.table-box {
  overflow: auto;
  padding-right: 30px;
}

.demo-box {
  margin-bottom: 20px;
}

.el-button--mini.is-round {
  padding: 7px 7px;
}

.padding-top {
    padding-bottom: 14px;
}
</style>


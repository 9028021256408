<template>
	<vue-scroll class="register-page align-vertical">
		<div class="form-wrapper align-vertical-middle">
			<el-alert
				title="Error!"
				type="error"
				:description="error"
				:closable="true"
				v-show="error"
				show-icon>
			</el-alert>
			<div class="form-box card-base card-shadow--extraLarge" v-loading="loading" v-on:keyup.enter.stop="register()">
				<img class="image-logo" src="/main.svg" alt="logo"/>
				
				<float-label class="styled">
					<input type="text" v-model="form.name" placeholder="Name">
				</float-label>
				<float-label class="styled">
					<input type="email" v-model="form.email" placeholder="Email">
				</float-label>
				<float-label class="styled">
					<input type="password" v-model="form.password" placeholder="Password">
				</float-label>
				<float-label class="styled">
					<input type="password" v-model="form.confirm_password" placeholder="Confirm Your Password">
				</float-label>
				
				<div class="flex">
					<div class="box grow"><el-checkbox v-show="false" v-model="form.terms">I read and accept terms</el-checkbox></div>
				</div>

				<div class="flex text-center center pt-30 pb-20">			
					<el-button plain size="small" @click="register()" :disabled="!passwordMatches()" class="signin-btn">
						REGISTER
					</el-button>
				</div>

				<div class="text-center login-box pt-10">
					Already have an account? <a @click="goToLogin()">Login</a>
				</div>
			</div>
		</div>
	</vue-scroll>
</template>

<script>
import firebase from "firebase/app";

export default {
	name: 'Register',
	data() {
		return {
			form: {
				email: '',
				password: '',
				confirm_password: '',
				name: '',
				terms: false
			},
			error: '',
			loading: false
		}
	},
	methods: {
		register() {
			this.loading = true
			firebase.auth()
			.createUserWithEmailAndPassword(this.form.email, this.form.password)
			.then(data => {
				data.user
					.updateProfile({
						displayName: this.form.name
					})
					.then(() => {
						this.$store.commit('setLogin', {email: data.user.email, displayName: data.user.displayName, id: data.user.uid})
						this.$router.push('dashboard')
					});
				})
			.catch(err => {
				this.loading = false
				this.error = err.message;
			});
		},
		passwordMatches() {
			return this.form.password == this.form.confirm_password
		},
		goToLogin() {
			this.$router.push('login')
		}
	}
}
</script>

<style lang="scss">
@import '../../../assets/scss/_variables';

.register-page {
	background: $text-color;
	margin-left: -45px;
	margin-right: -45px;

	.form-wrapper {
		width: 100%;
	}
	
	.form-box {
		width: 100%;
		max-width: 340px;
		padding: 30px;
		box-sizing: border-box;
		margin: 20px auto;

		a {
			font-size: 14px;
			color: $text-color-accent;
			text-decoration: none;
			font-weight: 500;
		}

		.image-logo {
			width: 80px;
			margin: 0px auto;
			margin-bottom: 50px;
			display: block;
		}

		.signin-btn {
			width: 160px;
		}

		.login-box {
			font-size: 14px;
		}
	}
}

@media (max-width: 768px) {
	.layout-container .container .view.register-page {
		margin-left: -5px;
		margin-right: -5px;
		max-width: calc(100% + 10px);
	}
}
</style>

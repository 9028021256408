var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-dashboard"},[_c('resize-observer',{on:{"notify":_vm.__resizeHanlder}}),_c('el-row',{attrs:{"gutter":30}},[_c('el-col',[_c('div',{staticClass:"flex justify-space-between"},[_c('h1',[_vm._v("Dashboard")]),_c('div',{directives:[{name:"intro",rawName:"v-intro",value:(
            'You can customise the period over which your dashboard displays. More options can be enabled in the Display Settings.'
          ),expression:"\n            'You can customise the period over which your dashboard displays. More options can be enabled in the Display Settings.'\n          "},{name:"intro-step",rawName:"v-intro-step",value:(2),expression:"2"}]},[_c('div',{staticClass:"radio-switcher"},[_c('el-radio-group',{model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}},_vm._l((_vm.displayPeriods),function(item){return _c('el-radio-button',{key:item.value,attrs:{"label":item.value}},[_vm._v(_vm._s(item.label))])}),1)],1)])])])],1),_c('el-row',{staticClass:"mt-30",attrs:{"gutter":30}},[_c('el-col',{attrs:{"xs":24,"sm":12,"md":14,"lg":16,"xl":18}},[_c('div',{directives:[{name:"intro",rawName:"v-intro",value:(
          'The buckets card shows all of your buckets and how much each outgoing bucket is costing you.'
        ),expression:"\n          'The buckets card shows all of your buckets and how much each outgoing bucket is costing you.'\n        "},{name:"intro-step",rawName:"v-intro-step",value:(1),expression:"1"}]},[_c('div',{staticClass:"card-base card-shadow--medium mb-30"},[_c('div',{staticClass:"p-20 flex justify-space-between"},[_c('div',[_c('h2',{staticClass:"mv-0 animated fadeInDown"},[_vm._v("Bucket Overview")]),_c('h4',{staticClass:"mt-5 mb-0 o-050 animated slideInUp"},[_vm._v(" By "+_vm._s(_vm._f("capitalize")(_vm.period))+" ")])])]),_c('div',{staticClass:"table-box"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"default-sort":{ prop: 'amount', order: 'descending' }}},[_c('el-table-column',{attrs:{"prop":"bucket","label":"Bucket","sortable":"","width":"180"}}),_c('el-table-column',{attrs:{"prop":"amount","label":"Amounts Due","sortable":"","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(_vm._f("currency")(scope.row.amount)))]}}])}),_c('el-table-column',{attrs:{"label":"% of Income","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-progress',{attrs:{"percentage":scope.row.progress}})]}}])})],1)],1)])])]),_c('el-col',{attrs:{"xs":24,"sm":12,"md":10,"lg":8,"xl":6}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(!_vm.asyncComponent),expression:"!asyncComponent"}],staticClass:"card-base card-shadow--medium mb-10 widget small-widget"},[_c('div',{staticClass:"widget-header ph-20 pt-20"},[_c('div',{staticClass:"flex justify-center align-center"},[_c('div',{staticClass:"widget-icon-box mr-20 animated fadeInRight"},[_c('i',{staticClass:"widget-icon mdi mdi-cash-multiple success-text fs-30"}),_c('div',{directives:[{name:"intro-hint",rawName:"v-intro-hint",value:(
                  'Shows how much cash you have left at the end of the period'
                ),expression:"\n                  'Shows how much cash you have left at the end of the period'\n                "}]},[_c('div',{staticClass:"badge-box"},[_c('span',{staticClass:"badge"},[_c('strong',{staticClass:"success-text"},[_vm._v(_vm._s(_vm._f("currency")((_vm.totalIncomes - _vm.totalOutgoings))))])])])])]),_c('div',{staticClass:"widget-info box grow text-truncate animated fadeInLeft"},[_c('div',{staticClass:"o-050 widget-title text-truncate pt-5 pb-10"},[_vm._v(" Incoming ")]),_c('h2',{staticClass:"m-0 text-truncate"},[_vm._v(_vm._s(_vm._f("currency")(_vm.totalIncomes)))])])])]),_c(_vm.asyncComponent,{tag:"component",attrs:{"type":'bar',"options":{ width: '100%', height: 80, fill: ['#56f19a'] },"data":_vm.incomes.toString()}})],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(!_vm.asyncComponent),expression:"!asyncComponent"}],staticClass:"card-base card-shadow--medium mb-30 widget small-widget"},[_c('div',{staticClass:"widget-header ph-20 pt-20"},[_c('div',{staticClass:"flex justify-center align-center"},[_c('div',{staticClass:"widget-icon-box mr-20 animated fadeInRight"},[_c('i',{staticClass:"widget-icon mdi mdi-receipt danger-text fs-30"}),_c('div',{directives:[{name:"intro-hint",rawName:"v-intro-hint",value:(
                  'Shows the your outcomes as a percentage of total income'
                ),expression:"\n                  'Shows the your outcomes as a percentage of total income'\n                "}]},[_c('div',{staticClass:"badge-box"},[_c('span',{staticClass:"badge"},[_c('i',{staticClass:"mdi mdi-trending-down info-text mr-10"}),_c('strong',{staticClass:"danger-text"},[_vm._v(_vm._s(_vm.outgoingPercentageOfIncome)+"%")])])])])]),_c('div',{staticClass:"widget-info box grow text-truncate animated fadeInLeft"},[_c('div',{staticClass:"o-050 widget-title text-truncate pt-5 pb-10"},[_vm._v(" Outgoing ")]),_c('h2',{staticClass:"m-0 text-truncate"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalOutgoings))+" ")])])])]),_c(_vm.asyncComponent,{tag:"component",attrs:{"type":'bar',"options":{ width: '100%', height: 80, fill: ['#ec205f'] },"data":_vm.outgoings.toString()}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
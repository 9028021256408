import Vue from "vue";
import { getField, updateField } from "vuex-map-fields";
import { periodMap } from "@/store/helpers";

const defaultIncome = {
  frequency: "week",
  date: "",
  name: "",
  amount: 0,
  category: "",
  editing: true,
  saved: false
};

export default {
  namespaced: true,
  mutations: {
    addOutgoing(state, income = { ...defaultIncome }) {
      state.tableData.unshift(income);
    },
    deleteOutgoing(state, index) {
      state.tableData.splice(index, 1);
    },
    replaceOutgoing(state, { index, income }) {
      Vue.set(state.tableData, index, income);
    },
    editingOutgoing(state, index) {
      const row = state.tableData[index];
      let newRow = { ...row };
      newRow.editing = true;
      newRow.saved = { ...row };
      Vue.set(state.tableData, index, newRow);
    },
    addCategory(state, category) {
      state.categories.push(category);
    },
    updateField
  },
  actions: {
    handleEdit({ commit, state }, index) {
      const row = state.tableData[index];
      // The current row is in the DB
      if (row.saved === true) {
        // Begin Editing
        commit("editingOutgoing", index);
        return;
      }

      if (row.saved === false) {
        // The row isn't in the db and beind discarded
        commit("deleteOutgoing", index);
        return;
      }

      // Row is being reset back to previous state
      let newRow = { ...row.saved };
      newRow.editing = false;
      commit("replaceOutgoing", { index, income: newRow });
    },
    handleSave({ commit, state }, index) {
      const row = state.tableData[index];
      let newRow = { ...row };
      // Turn off the editing UI
      newRow.editing = !row.editing;
      // This will be set later when we go to the db, for now pretend it worked
      newRow.saved = true;

      // If any new categories were added
      if (!state.categories.includes(row.category)) {
        // Add it to our prefilled categories
        commit("addCategory", String(row.category));
      }

      commit("replaceOutgoing", { index, income: newRow });
    }
  },
  state: {
    tableData: [
      {
        frequency: "week",
        date: "2016-05-03",
        name: "Rent",
        amount: 400,
        category: "Home",
        editing: false,
        saved: true
      }
    ],
    input: "",
    categories: ["Food", "Insurance", "Home", "Entertainment"]
  },
  getters: {
    getField,
    getOutgoingsByPeriod: state => period => {
      let finalAmount = 0;
      state.tableData.forEach((row, index) => {
        finalAmount += periodMap[row["frequency"]][period](row["amount"]);
      });

      return finalAmount;
    }
  }
};

import Vue from "vue";
import { getField, updateField } from "vuex-map-fields";

const defaultIncome = {
  frequency: "",
  date: "",
  name: "",
  amount: "",
  category: "",
  editing: true,
  saved: false
};

export default {
  namespaced: true,
  mutations: {
    addIncome(state, income = { ...defaultIncome }) {
      state.tableData.unshift(income);
      if (state.primary_income != -1) {
        state.primary_income = state.primary_income +1;
      }
    },
    deleteIncome(state, index) {
      state.tableData.splice(index, 1);
      if (state.primary_income != -1) {
        if (index == state.primary_income) {
          state.primary_income = -1;
        } else if (index < state.primary_income) {
          state.primary_income = state.primary_income -1;
        }
      }
    },
    replaceIncome(state, { index, income }) {
      Vue.set(state.tableData, index, income);
    },
    editingIncome(state, index) {
      const row = state.tableData[index];
      let newRow = { ...row };
      newRow.editing = true;
      newRow.saved = { ...row };
      Vue.set(state.tableData, index, newRow);
    },
    addCategory(state, category) {
      state.categories.push(category);
    },
    changePrimaryIncome(state, incomeIndex) {
      state.primary_income = incomeIndex
    },
    updateField
  },
  actions: {
    handleEdit({ commit, state }, index) {
      const row = state.tableData[index];
      // The current row is in the DB
      if (row.saved === true) {
        // Begin Editing
        commit("editingIncome", index);
        return;
      }

      if (row.saved === false) {
        // The row isn't in the db and beind discarded
        commit("deleteIncome", index);
        return;
      }

      // Row is being reset back to previous state
      let newRow = { ...row.saved };
      newRow.editing = false;
      commit("replaceIncome", { index, income: newRow });
    },
    handleSave({ commit, state }, index) {
      const row = state.tableData[index];
      let newRow = { ...row };
      // Turn off the editing UI
      newRow.editing = !row.editing;
      // This will be set later when we go to the db, for now pretend it worked
      newRow.saved = true;

      // If any new categories were added
      if (!state.categories.includes(row.category)) {
        // Add it to our prefilled categories
        commit("addCategory", String(row.category));
      }

      commit("replaceIncome", { index, income: newRow });
    },
    handlePrimaryIncomeChange({commit}, index) {
      commit('changePrimaryIncome', index)
    }
  },
  state: {
    tableData: [
      {
        frequency: "week",
        date: "2016-05-03",
        name: "Work",
        amount: 1278.92,
        category: "Salary",
        editing: false,
        saved: true
      }
    ],
    inputPlaceholder: "$60 weekly for my Rental Property starting 08/02/2019",
    input: "",
    categories: ["Salary", "Investment", "Rental Income", "Repayment"],
    primary_income: -1
  },
  getters: {
    getField
  }
};

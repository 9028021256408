const DAY = 365;
const WEEK = 52;
const BIWEEK = 26;
const MONTH = 12;
const BIMONTH = MONTH *2;
const QUARTER = 4;
const BIANNUAL = 2;
const ANNUAL = 1;

/**
 * Format: Amount to be converted's period is the top-level key
 *
 * e.g. If we want to work out what a tableData item is at a period of Weekly then you:
 * let displayAmount = periodMap[row['period']]['weekly'](row['amount'])
 */

const periodMap = {
  day: {
    "bi-week": amount => (amount * DAY) / BIWEEK,
    week: amount => (amount * DAY) / WEEK,
    "bi-month": amount => (amount * DAY) / (MONTH * 2),
    month: amount => (amount * DAY) / MONTH,
    day: amount => amount,
    quarter: amount => (amount * DAY) / QUARTER,
    "bi-annual": amount => (amount * DAY) / BIANNUAL,
    annual: amount => amount * DAY
  },
  "bi-week": {
    "bi-week": amount => amount,
    week: amount => (amount * BIWEEK) / WEEK,
    "bi-month": amount => (amount * BIWEEK) / BIMONTH,
    month: amount => (amount * BIWEEK) / MONTH,
    day: amount => (amount * BIWEEK) / DAY,
    quarter: amount => (amount * BIWEEK) / QUARTER,
    "bi-annual": amount => (amount * BIWEEK) / BIANNUAL,
    annual: amount => amount * BIWEEK
  },
  week: {
    "bi-week": amount => (amount * WEEK) / BIWEEK,
    week: amount => amount,
    "bi-month": amount => (amount * WEEK) / (MONTH * 2),
    month: amount => (amount * WEEK) / MONTH,
    day: amount => (amount * WEEK) / DAY,
    quarter: amount => (amount * WEEK) / QUARTER,
    "bi-annual": amount => (amount * WEEK) / BIANNUAL,
    annual: amount => amount * WEEK
  },
  "bi-month": {
    "bi-week": amount => (amount * BIMONTH) / BIWEEK,
    week: amount => (amount * BIMONTH) / WEEK,
    "bi-month": amount => (amount),
    month: amount => (amount * BIMONTH) / MONTH,
    day: amount => (amount * BIMONTH) / DAY,
    quarter: amount => (amount * BIMONTH) / QUARTER,
    "bi-annual": amount => (amount * BIMONTH) / BIANNUAL,
    annual: amount => amount * BIMONTH
  },
  month: {
    "bi-week": amount => (amount * MONTH) / BIWEEK,
    week: amount => (amount * MONTH) / WEEK,
    "bi-month": amount => (amount * MONTH) / (MONTH * 2),
    month: amount => amount,
    day: amount => (amount * MONTH) / DAY,
    quarter: amount => (amount * MONTH) / QUARTER,
    "bi-annual": amount => (amount * MONTH) / BIANNUAL,
    annual: amount => amount * MONTH
  },
  quarter: {
    "bi-week": amount => (amount * QUARTER) / BIWEEK,
    week: amount => (amount * QUARTER) / WEEK,
    "bi-month": amount => (amount * QUARTER) / (MONTH * 2),
    month: amount => (amount * QUARTER) / MONTH,
    day: amount => (amount * QUARTER) / DAY,
    quarter: amount => amount,
    "bi-annual": amount => (amount * QUARTER) / BIANNUAL,
    annual: amount => amount * QUARTER
  },
  "bi-annual": {
    "bi-week": amount => (amount * BIANNUAL) / BIWEEK,
    week: amount => (amount * BIANNUAL) / WEEK,
    "bi-month": amount => (amount * BIANNUAL) / (MONTH * 2),
    month: amount => (amount * BIANNUAL) / MONTH,
    day: amount => (amount * BIANNUAL) / DAY,
    quarter: amount => (amount * BIANNUAL) / QUARTER,
    "bi-annual": amount => amount,
    annual: amount => amount * BIANNUAL
  },
  annual: {
    "bi-week": amount => (amount * ANNUAL) / BIWEEK,
    week: amount => (amount * ANNUAL) / WEEK,
    "bi-month": amount => (amount * ANNUAL) / (MONTH * 2),
    month: amount => (amount * ANNUAL) / MONTH,
    day: amount => (amount * ANNUAL) / DAY,
    quarter: amount => (amount * ANNUAL) / QUARTER,
    "bi-annual": amount => (amount * ANNUAL) / BIANNUAL,
    annual: amount => amount
  }
};

const periods = [
  { value: "day", label: "Daily" },
  { value: "week", label: "Weekly" },
  { value: "bi-week", label: "Fortnightly" },
  { value: "bi-month", label: "Bi-Monthly" },
  { value: "month", label: "Monthly" },
  { value: "quarter", label: "Quarterly" },
  { value: "bi-annual", label: "Bi-Annually" },
  { value: "annual", label: "Annually" }
];

export { periodMap, periods };
